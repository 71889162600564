import { createSelector } from "reselect";
import { nonFipsSettingsFetcher } from "./services";

export const getContexNonFipsAddonPacksEnabed = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result?.fipsPackConfig?.mode === "nonFipsEnabled"
);

export const getContextNonFipsFeaturesEnabed = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result?.fipsClusterFeatureConfig?.mode === "nonFipsEnabled"
);

export const getContextNonFipsClusterImportEnabed = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result?.fipsClusterImportConfig?.mode === "nonFipsEnabled"
);

export const getContextNonFipsSettings = createSelector(
  nonFipsSettingsFetcher.selector,
  ({ result }) => result
);

export const getNonFipsFeaturesVisibility = createSelector(
  (state) => state.auth.currentOrganization?.securityMode,
  getContextNonFipsFeaturesEnabed,
  (securityMode, isNonFipsFeaturesEnabled) => {
    if (securityMode !== "fips") return true;
    return isNonFipsFeaturesEnabled;
  }
);

export const getNonFipsClusterImportVisibility = createSelector(
  (state) => state.auth.currentOrganization?.securityMode,
  getContextNonFipsClusterImportEnabed,
  (securityMode, isNonFipsClusterImportEnabed) => {
    if (securityMode !== "fips") return true;
    return isNonFipsClusterImportEnabed;
  }
);
